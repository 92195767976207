const styles = {
  // Shared
  divider: {
    borderBottom: "1px solid #054752",
    margin: "40px auto 0 !important",
    maxWidth: "1250px",
  },
  title: {
    fontSize: ["28px", "33px", "41px"],
    color: "cgTeal",
    fontWeight: 600,
    marginBottom: ".8em !important",
    "text-align": "left",
  },
  title2: {
    fontSize: ["28px", "33px", "41px"],
    color: "cgTeal",
    fontWeight: 600,
    marginBottom: ".8em !important",
    "text-align": "center",
  },
  section: {
    width: "100%",
    "&.carousel": {
      margin: ["10px 0 30px", "0 0 30px"],
      "text-align": "center",
    },
    "&.impact": {
      backgroundColor: "lightgray",
      padding: "45px 0 30px",
      "text-align": "center",
      margin: "20px 0 0",
    },
    ".title-span": {
      fontSize: "20px",
      fontWeight: 600,
      display: "block",
      marginBottom: ".7em",
      color: "cgGold",
    },
    a: {
      marginBottom: ".5em",
    },
  },
  // Article Section
  articleSection: {
    justifyContent: "space-between",
  },
  // Impact
  iconsWrap: {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "920px",
    width: "90%",
    margin: "0 auto !important",
    "flex-wrap": "wrap",
    paddingTop: "35px",
    span: {
      fontWeight: "500",
    },
    "@media only screen and (min-width: 560px)": {
      justifyContent: "space-between",
    },
  },
  imageWrapper: {
    "text-align": "center",
    color: "#fff",
    padding: "0 10px 20px",
    img: {
      margin: "0 auto",
      width: "auto",
      height: ["40px", "55px", "65px"],
      "object-fit": "cover",
      marginBottom: "10px",
    },
    ":hover": {
      ".overlay": {
        opacity: 1,
      },
      ".text": {
        opacity: 1,
      },
      a: {
        color: "white !important",
      },
    },
    a: {
      position: "relative" as "relative",
      overflow: "hidden",
      display: "block",
      "> div img": {
        transition: "all 0.3s ease 0s",
      },
    },
    span: {
      color: "cgTeal",
      fontSize: ["17px", "20px"],
      fontWeight: 600,
    },
  },
  // Reviews
  reviewSectionWrap: {
    "text-align": "center",
    margin: "100px 0 40px 0",
    "&.container": {
      maxWidth: "1250px",
      margin: "80px auto",
      padding: "0 20px",
    },
  },
  reviewWrap: {
    "text-align": "left",
    margin: "0 auto",
    justifyContent: "space-between",
    "flex-direction": "column",
    ".image-wrap": {
      width: "90px",
      marginRight: "20px",
      alignSelf: "center",
      marginBottom: "15px",
      "@media only screen and (min-width: 600px)": {
        alignSelf: "flex-start",
        width: "70px",
      },
      "@media only screen and (min-width: 1024px)": {
        width: "80px",
      },
    },
    ".text-wrap": {
      width: "100%",
      display: "flex",
      "flex-direction": "column",
      "text-align": "center",
      "@media only screen and (min-width: 600px)": {
        "text-align": "left",
      },
    },
    ".text p": {
      marginTop: 0,
      marginBottom: 3,
      fontSize: "17px",
      lineHeight: [1.7, 2],
      "font-style": "italic",
      fontWeight: 600,
      color: "cgTeal",
    },
    span: {
      fontWeight: 800,
      color: "cgTeal",
    },
    img: {
      display: "block",
    },
    ".gatsby-image-wrapper": {
      height: "90px",
      borderRadius: "50%",
      border: "1px solid #D79A2B",
      "@media only screen and (min-width: 600px)": {
        height: "70px",
      },
      "@media only screen and (min-width: 1024px)": {
        height: "80px",
      },
    },
    ".review": {
      backgroundColor: "white",
      marginBottom: "40px",
      paddingBottom: "40px",
      "flex-direction": "column",
      borderBottom: "0.5px solid #054752",
      "@media only screen and (min-width: 600px)": {
        "flex-direction": "row",
      },
      ".row": {
        display: "flex",
        "flex-direction": "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "20px",
        "@media only screen and (max-width: 590px)": {
          "flex-direction": "column",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
  },
};

export default styles;

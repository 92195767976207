import { Box, Flex, Text } from "theme-ui";
import { RichText } from "prismic-reactjs";
import styles from "./new-shop.style";
import { linkResolver } from "../../utils/linkResolver";
import cx from "classnames";

interface Props {
  content: any;
  landing?: boolean;
  container?: boolean;
}

const Reviews: React.FC<Props> = ({ content, landing, container }) => {
  return (
    <Box
      sx={styles.reviewSectionWrap}
      className={cx("", {
        landing: landing,
        container: container,
      })}
    >
      <Text as="h2" sx={styles.title}>
        Customer Reviews
      </Text>
      <Flex sx={styles.reviewWrap}>
        {content.items?.map((item: any, index: number) =>
          item.text.raw.length ? (
            <Box className="review" key={`blurb-${index}`}>
              <div className="row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 61 63"
                  fill="none"
                >
                  <path
                    d="M11.6485 45.5189C9.03054 42.6928 7.625 39.523 7.625 34.3848C7.625 25.3431 13.8699 17.2392 22.9513 13.2324L25.221 16.7923C16.7445 21.4526 15.0873 27.5002 14.4265 31.3132C15.7914 30.595 17.5782 30.3444 19.3294 30.5098C23.9145 30.9412 27.5288 34.7671 27.5288 39.523C27.5288 41.921 26.5916 44.2208 24.9233 45.9164C23.255 47.6121 20.9923 48.5647 18.633 48.5647C17.3282 48.5531 16.0388 48.2778 14.8397 47.7549C13.6406 47.232 12.5558 46.472 11.6485 45.5189ZM37.0651 45.5189C34.4472 42.6928 33.0417 39.523 33.0417 34.3848C33.0417 25.3431 39.2865 17.2392 48.3679 13.2324L50.6376 16.7923C42.1612 21.4526 40.504 27.5002 39.8432 31.3132C41.208 30.595 42.9948 30.3444 44.746 30.5098C49.3312 30.9412 52.9455 34.7671 52.9455 39.523C52.9455 41.921 52.0082 44.2208 50.3399 45.9164C48.6716 47.6121 46.4089 48.5647 44.0496 48.5647C42.7449 48.5531 41.4554 48.2778 40.2563 47.7549C39.0573 47.232 37.9725 46.472 37.0651 45.5189Z"
                    fill="#F69E10"
                  />
                </svg>
                <div className="text-wrap">
                  <div className="text">
                    {RichText.render(item.text.raw, linkResolver)}
                  </div>
                  {item.name && <Text as="span">{`- ${item.name}`}</Text>}
                </div>
              </div>
            </Box>
          ) : null
        )}
      </Flex>
    </Box>
  );
};

export default Reviews;
